/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'core-js/fn/object/assign'
import 'core-js/fn/string/includes'
import 'core-js/fn/array/includes'
